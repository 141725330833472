<app-auth-layout>
  <div class="status" status>
    <app-scan-status *ngIf="domain" [domain]="domain"></app-scan-status>
    <app-logos></app-logos>
  </div>

  <div class="form" [formGroup]="form">
    <h1 class="title">Create Your Free Diib® Account</h1>
    <h1 class="title-grey">Free Forever, No Credit Card Required</h1>
    <div class="subtitle">Already have an account? <a routerLink="/login">Sign In</a></div>
    <app-notification-outlet></app-notification-outlet>

    <input name="fullName" placeholder="Full Name" formControlName="fullName" autofocus />
    <div *ngIf="fullName.dirty && fullName.errors" class="error-message">Please enter your name.</div>

    <input type="email" name="email" placeholder="Email" formControlName="email" />
    <div *ngIf="email.dirty && email.errors?.required" class="error-message">Please enter an email address.</div>
    <div *ngIf="email.dirty && email.errors?.email" class="error-message">Please enter a valid email.</div>

    <input type="password" name="password" placeholder="Password" autocomplete="off" formControlName="password" />
    <div *ngIf="password.dirty && password.errors?.minlength" class="error-message">
      Should be at least 8 characters long.
    </div>
    <div *ngIf="password.dirty && password.errors?.required" class="error-message">Please create a password.</div>

    <button *appLoading="submitting" class="primary" app-button (click)="submit()">Sign Up Free</button>
    <div *ngIf="loading" class="loading-wrapper">
      <app-loading-placeholder></app-loading-placeholder>
    </div>

    <app-facebook-auth-button (userReceived)="onUserReceivedFromFacebook($event)"></app-facebook-auth-button>

    <app-google-auth-button
      *ngIf="isGoogleApiLoaded$ | async"
      class="button--last"
      text="signup_with"
      (tokenReceived)="onSignUpWithGoogle($event)"
    ></app-google-auth-button>
  </div>

  <div *ngIf="!accountCreated || !isGADetected" class="illustration">
    <img src="assets/images/illustration.svg" alt="illustration" />
  </div>
  <div *ngIf="!accountCreated" class="terms">
    By signing up, you confirm that you've read and accepted our User Notice and
    <a href="https://diib.com/privacy/" target="_blank">Privacy Policy</a>.
  </div>
  <app-signup-used-by *ngIf="accountCreated && isGADetected"></app-signup-used-by>

  <div class="faq" extra>
    <app-signup-faq></app-signup-faq>
  </div>
</app-auth-layout>
