import { Website } from '../../common/models';

export interface WebsitesListState {
  items: Website[];
}

const defaultWebsitesListState: WebsitesListState = {
  items: [],
};

export const DEFAULT_WEBSITES_LIST_STATE = defaultWebsitesListState;
