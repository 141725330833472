import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { AuthRoutingModule } from './auth-routing.module';
import { FacebookAuthButtonComponent } from './components/facebook-auth-button/facebook-auth-button.component';
import { GoogleAuthButtonComponent } from './components/google-auth-button/google-auth-button.component';
import { LogosComponent } from './components/logos/logos.component';
import { NotificationStateComponent } from './components/notification-state/notification-state.component';
import { ScanStatusComponent } from './components/scan-status/scan-status.component';
import { SignUpFAQComponent } from './components/signup-faq/signup-faq.component';
import { UsedByComponent } from './components/used-by/used-by.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignupComponent } from './signup/signup.component';
import { SignupGaSyncComponent } from './signup-ga-sync/signup-ga-sync.component';
import { SwitchComponent } from './switch/switch.component';
import { environment } from '../../environments/environment';
import { NotificationModule } from '../_notification/notification.module';
import { SharedModule } from '../shared/shared.module';

/**
 * TODO: Currently is imported directly into app.module. Should be a lazy loaded module. Has no default route.
 */
@NgModule({
  imports: [CommonModule, SharedModule, RecaptchaV3Module, NotificationModule, AuthRoutingModule, LogosComponent],
  declarations: [
    AuthLayoutComponent,
    ForgotPasswordComponent,
    LoginComponent,
    ResetPasswordComponent,
    SignupComponent,
    SwitchComponent,
    FacebookAuthButtonComponent,
    ScanStatusComponent,
    UsedByComponent,
    SignupGaSyncComponent,
    GoogleAuthButtonComponent,
    SignUpFAQComponent,
    NotificationStateComponent,
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.gRecaptcha3Key,
    },
  ],
})
export class AuthModule {}
