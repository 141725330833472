import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { ChamberGuard } from './core/guards/chamber.guard';
import { WebsiteGuardService } from './core/guards/website.guard';
import { FeaturedMemberGuardService } from './featured-member/featured-member-guard.service';
import { environment } from '../environments/environment';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },

  // TODO: Remove pricing aliases when new Payment page is ready
  // (query params only work with absolute routes that contain '/' on the redirect)
  { path: 'dashboard/freetrial', redirectTo: 'pricing', pathMatch: 'full' },
  { path: 'dashboard/free14daytrial', redirectTo: 'pricing', pathMatch: 'full' },
  {
    path: 'dashboard/uk-lockdown-assistance',
    redirectTo: 'pricing',
    pathMatch: 'full',
  },

  // aliases
  { path: 'dashboard/channels', redirectTo: 'channels', pathMatch: 'full' },
  { path: 'dashboard/agency', redirectTo: 'agency', pathMatch: 'full' },
  { path: 'dashboard/import', redirectTo: 'import', pathMatch: 'full' },
  { path: 'dashboard/payment-history', redirectTo: 'payment-history', pathMatch: 'full' },
  { path: 'dashboard/billing/cancel', redirectTo: 'billing/cancel', pathMatch: 'full' },
  { path: 'dashboard/services/checkout/choose/all', redirectTo: 'services/checkout/choose/all', pathMatch: 'full' },
  { path: 'dashboard/services', redirectTo: 'services', pathMatch: 'full' },
  { path: 'dashboard/services/pro-managed', redirectTo: 'services/pro-managed', pathMatch: 'full' },
  {
    path: 'dashboard/services/backlinks-optimization',
    redirectTo: 'services/backlinks-optimization',
    pathMatch: 'full',
  },
  {
    path: 'dashboard/services/authority-boost',
    redirectTo: 'services/authority-boost',
    pathMatch: 'full',
  },
  {
    path: 'dashboard/services/content-blast',
    redirectTo: 'services/content-blast',
    pathMatch: 'full',
  },
  {
    path: 'dashboard/services/sitemap',
    redirectTo: 'services/sitemap',
    pathMatch: 'full',
  },
  {
    path: 'featured-member',
    title: 'Featured Member | Diib™ Inc.',
    canActivate: [AuthGuard, ChamberGuard, WebsiteGuardService, FeaturedMemberGuardService],
    loadComponent: () => import('./featured-member/featured-member.component').then((c) => c.FeaturedMemberComponent),
  },
  {
    path: 'chambers',
    title: 'Chamber | Diib™ Inc.',
    loadChildren: () => import('./chambers/chambers.module').then((m) => m.ChambersModule),
  },
  {
    path: 'dashboard/featured-member',
    redirectTo: 'featured-member',
    pathMatch: 'full',
  },
  {
    path: 'survey',
    title: 'Survey | Diib™ Inc.',
    canActivate: [AuthGuard, ChamberGuard],
    loadChildren: () => import('./survey/survey.module').then((m) => m.SurveyModule),
  },
  {
    path: 'unsubscribe/:token',
    title: 'Unsubscribe | Diib™ Inc.',
    loadComponent: () => import('./unsubscribe/unsubscribe.component').then((c) => c.UnsubscribeComponent),
  },
  {
    path: 'handshake-builder',
    title: 'Handshake Builder | Diib™ Inc.',
    canActivate: [AuthGuard, WebsiteGuardService],
    loadChildren: () => import('./handshake-builder/handshake-builder.module').then((m) => m.HandshakeBuilderModule),
  },

  // Old payment aliases. TODO: Should be removed when redirects are configured on web server
  {
    path: 'dashboard/pricing/69-annual-promo-offer',
    redirectTo: '/pricing',
  },
  { path: 'dashboard/diibpromotion', redirectTo: '/pricing' },
  { path: 'promo-monthly-or-annual-1', redirectTo: '/pricing/promo-monthly-or-annual-1' },
  { path: 'dashboard/diibpromotionannual', redirectTo: '/pricing' },
  { path: 'promo-special-annual', redirectTo: '/pricing' },
  { path: 'dashboard/stimuluspackage', redirectTo: '/pricing' },

  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'top',
      enableTracing: environment.debug.routing,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
