import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { map, pairwise, tap } from 'rxjs/operators';

import { goBack, setBackLink } from './global.actions';
import { AppState } from './reducers';
import { RouterState } from './router-state';
import { selectBackLink } from './selectors';

@Injectable()
export class RouterEffects {
  navigated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      pairwise(),
      map(([{ payload }]: RouterNavigatedAction<RouterState>[]) => {
        return setBackLink({ backLink: payload.routerState.url });
      }),
    ),
  );

  back$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(goBack),
        concatLatestFrom(() => this.store.select(selectBackLink)),
        tap(([, backlink]) => {
          if (backlink) {
            this.router.navigateByUrl(backlink, { replaceUrl: true });
          } else if (window.history.length) {
            window.history.back();
          } else {
            this.router.navigateByUrl('/dashboard', { replaceUrl: true });
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
  ) {}
}
