import { isPlatformBrowser } from '@angular/common';
import { Inject, ModuleWithProviders, NgModule, PLATFORM_ID } from '@angular/core';

import { FBPixelConfiguration } from './fb-pixel.model';
import { FBPixelService } from './fb-pixel.service';

@NgModule()
export class FBPixelModule {
  private static config: FBPixelConfiguration | null = null;

  constructor(
    private pixel: FBPixelService,
    @Inject(PLATFORM_ID) platformId: NonNullable<unknown>,
  ) {
    if (!FBPixelModule.config) {
      throw Error(
        'FBPixel Service is not configured correctly. Pass the `pixelId` property to the `forRoot()` function',
      );
    }

    if (FBPixelModule.config.enabled && isPlatformBrowser(platformId)) {
      this.pixel.initialize();
    }
  }

  static forRoot(config: FBPixelConfiguration): ModuleWithProviders<FBPixelModule> {
    this.config = config;
    const pixelId = config.pixelId;
    this.verifyPixelId(pixelId);

    return {
      ngModule: FBPixelModule,
      providers: [FBPixelService, { provide: 'config', useValue: config }],
    };
  }

  /**
   * Verifies the Pixel ID that was passed into the configuration.
   * - Checks if Pixel was initialized
   * @param pixelId Pixel ID to verify
   */
  private static verifyPixelId(pixelId: string): void {
    // Have to verify first that all Pixel IDs follow the same 15 digit format
    if (pixelId === null || pixelId === undefined || pixelId.length === 0) {
      throw Error('Invalid Facebook Pixel ID. Did you pass the ID into the forRoot() function?');
    }
  }
}
