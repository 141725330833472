import { createReducer, on } from '@ngrx/store';

import {
  loadUser,
  loadUserFail,
  loadUserSuccess,
  setContactInfo,
  setFeaturedMemberStatus,
  loadUserActivity,
  loadUserActivitySuccess,
  loadContactInfoSuccess,
} from './actions';
import { DEFAULT_USER_STATE } from './state';
import { EntityState } from '../../common';

export const userReducer = createReducer(
  DEFAULT_USER_STATE,
  on(loadUser, (state) => ({ ...state, userState: EntityState.Loading })),
  on(loadUserSuccess, (state, { user }) => ({ ...state, user: { ...user }, userState: EntityState.Loaded })),
  on(loadUserFail, (state) => ({ ...state, userState: EntityState.Error })),
  on(setFeaturedMemberStatus, (state, { status: featuredMemberStatus }) => ({ ...state, featuredMemberStatus })),
  on(setContactInfo, loadContactInfoSuccess, (state, { contacts }) => ({ ...state, contacts })),
  on(loadUserActivity, (state) => ({ ...state })),
  on(loadUserActivitySuccess, (state, { activity }) => ({ ...state, activity })),
);
