<app-auth-layout>
  <div class="status" status>
    <app-scan-status [domain]="domain"></app-scan-status>
    <app-logos></app-logos>
  </div>

  <div class="controls">
    <p class="controls__info">
      Perfect! Your website already has Google Analytics so you can start using the Answer Engine.
    </p>
    <button class="controls__button controls__button--import" app-button view="primary" (click)="importGA()">
      Import from Google Analytics
    </button>
    <button
      class="controls__button controls__button--skip"
      id="sign-up-skip-button"
      app-button
      view="secondary"
      (click)="skipImportGA()"
    >
      I'll do it later
    </button>
  </div>

  <app-signup-used-by></app-signup-used-by>

  <div class="faq" extra>
    <app-signup-faq></app-signup-faq>
  </div>
</app-auth-layout>
