import { createReducer, on } from '@ngrx/store';

import { setAcp, setBackLink } from './global.actions';

export interface GlobalState {
  acp: boolean;
  backLink?: string;
}

export const initialState: GlobalState = {
  acp: false,
};

export const globalReducer = createReducer(
  initialState,
  on(setAcp, (state, { acp }) => ({ ...state, acp })),
  on(setBackLink, (state, { backLink }) => ({ ...state, backLink })),
);
