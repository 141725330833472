<button class="fb-auth-btn" (click)="loginWithFacebook()">
  <img
    class="fb-auth-btn__img"
    src="assets/images/icons/facebook_icon.svg"
    decoding="async"
    width="24"
    height="24"
    alt="Facebook icon"
  />
  Continue with Facebook
</button>
