import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  loadActiveSubscriptions,
  loadActiveSubscriptionsSuccess,
  loadHasProAccess,
  loadHasProAccessSuccess,
  loadPaymentMethod,
  loadPaymentMethodSuccess,
  loadSubscription,
  loadSubscriptionSuccess,
} from './actions';
import { BillingApiService } from '../../core/services/billing-api.service';

@Injectable()
export class BillingEffects {
  loadHasProAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadHasProAccess),
      exhaustMap(() =>
        this.billingService
          .getHasProAccess()
          .pipe(map((isProAccessProvided) => loadHasProAccessSuccess({ isProAccessProvided }))),
      ),
    ),
  );

  loadSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSubscription),
      exhaustMap(() =>
        this.billingService.getSubscription().pipe(map((subscription) => loadSubscriptionSuccess({ subscription }))),
      ),
    ),
  );

  loadPaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPaymentMethod),
      exhaustMap(() =>
        this.billingService
          .getPaymentMethod()
          .pipe(map((paymentMethod) => loadPaymentMethodSuccess({ paymentMethod }))),
      ),
    ),
  );

  loadActiveSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadActiveSubscriptions),
      exhaustMap(() =>
        this.billingService
          .getActiveSubscriptions()
          .pipe(map((subscriptions) => loadActiveSubscriptionsSuccess({ subscriptions }))),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private billingService: BillingApiService,
  ) {}
}
