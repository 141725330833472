<div class="warning">
  <h3 class="title">
    Shoot! It looks like there’s no account associated with this
    {{ authProvider === AuthProvider.FACEBOOK ? 'Facebook' : 'Google' }} profile.
  </h3>

  <ul class="list">
    <li class="list-item">
      <img
        class="list-item__tick"
        ngSrc="assets/images/icons/check-circle.svg"
        width="16"
        height="16"
        alt="Check OK icon"
      />
      <p class="list-item__text">
        If you know you already have a Diib account, you can try to log in again with your email or
        {{ authProvider === AuthProvider.FACEBOOK ? 'Google' : 'Facebook' }} on this page.
      </p>
    </li>

    <li class="list-item">
      <img
        class="list-item__tick"
        ngSrc="assets/images/icons/check-circle.svg"
        width="16"
        height="16"
        alt="Check OK icon"
      />
      <p class="list-item__text">
        If you need to create a new account,
        <a class="list-item__link" appHighlight routerLink="/{{ AuthPath.Signup }}">click here</a>.
      </p>
    </li>

    <li class="list-item">
      <img
        class="list-item__tick"
        ngSrc="assets/images/icons/check-circle.svg"
        width="16"
        height="16"
        alt="Check OK icon"
      />
      <p class="list-item__text">
        Feeling frustrated? Or just need more help, email the team at
        <app-mailto-support class="list-item__mail-to" [hasPro]="false"></app-mailto-support>
        and we will make sure you are taken care of.
      </p>
    </li>
  </ul>
</div>
