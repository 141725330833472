import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OnChange } from 'on-property-change';

import { randomInt } from '../../../common';

@Component({
  selector: 'app-scan-status',
  templateUrl: './scan-status.component.html',
  styleUrls: ['./scan-status.component.scss'],
  standalone: false,
})
export class ScanStatusComponent implements OnInit {
  @Input() domain: string;
  @Input() completed: boolean = false;

  @Output() complete = new EventEmitter<void>();

  steps: string[] = [
    'Finding backlinks',
    'Testing for spammy backlinks',
    'Analyzing keyword rankings',
    'Calculating keyword density',
    'Examining metatags',
    'Testing website speed',
    'Testing website security',
    'Checking email deliverability',
    'Testing IP for blacklist',
    'Looking for sitemap',
    'Organizing initial objectives',
    'Finding alerts',
  ];

  stepIndex = 0;
  step: string;

  stepTimeout: any;

  ngOnInit(): void {
    this.step = this.steps[this.stepIndex];
    this.scheduleStepChange();
  }

  private scheduleStepChange(): void {
    this.stepTimeout = setTimeout(
      () => {
        if (this.stepIndex < this.steps.length - 1) {
          this.stepIndex++;
          this.step = this.steps[this.stepIndex];
          this.scheduleStepChange();
        } else {
          this.completed = true;
        }
      },
      randomInt(500, 2000),
    );
  }

  @OnChange('completed')
  checkCompleted(): void {
    if (this.completed) {
      clearTimeout(this.stepTimeout);
      this.complete.emit();
    }
  }
}
