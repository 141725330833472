<h1>FAQs</h1>

<app-faq-list>
  <app-faq-list-item>
    <ng-template #header>
      <span>Why do I need to create an account to use Diib?</span>
    </ng-template>

    <ng-template #body>
      Diib is not just an external website scanning tool. Our intelligent analytics platform integrates with your
      existing Google Analytics, Facebook Insights, and Google Business Profile accounts to provide you with a custom
      <b>Growth Plan</b> based on your actual data. Of course we also constantly scan your website for things like
      security, speed, rankings and backlinks, but combining all this information makes for a much more useful tool than
      just a simple website scan.
    </ng-template>
  </app-faq-list-item>

  <app-faq-list-item>
    <ng-template #header>
      <span>Does Diib send me marketing emails and updates? What if I don’t want them?</span>
    </ng-template>

    <ng-template #body>
      Yes, when you create an account on Diib you will receive a <b>Weekly Snapshot</b> email and occasionally updates
      and marketing emails. You will also receive email alerts if we notice anything out of the ordinary (i.e. website
      security/speed issues, traffic losses or ranking changes due to search algorithm updates). You can easily turn all
      email notifications off inside your dashboard or you can manage how often you’d like to receive emails by clicking
      unsubscribe at the bottom of any Diib email. We try to only send emails we think you will find valuable and
      <b>we never sell or trade your email address with any other company</b>.
    </ng-template>
  </app-faq-list-item>

  <app-faq-list-item>
    <ng-template #header>
      <span>Why should I trust Diib?</span>
    </ng-template>

    <ng-template #body>
      Diib is a corporation in good-standing based in the United States. We have helped over {{ globalUsers }} website
      owners in 160+ countries. We have been featured in numerous publications like
      <b>Entrepreneur, The Times U.K, PC Mag, Business Rockstars</b>, as well as many others. Since 2012, we have been
      100% focused on helping our members grow and we have never sold or traded any members’ data, emails, or any other
      information with any other company or entity. We respect our members’ privacy to the highest degree possible no
      matter where on the globe they reside.
    </ng-template>
  </app-faq-list-item>

  <app-faq-list-item>
    <ng-template #header>
      <span>How much does Diib cost and what payment methods are accepted?</span>
    </ng-template>

    <ng-template #body>
      <p>
        Our Free plan is just that - 100% free. It includes limited weekly scans, email alerts, and email access to
        additional support as needed. We also offer a Welcome to Diib package that is
        {{ freePrice | currency : 'USD' : 'symbol' : '1.0-2' }} for the first month and then renews at
        {{ proPrice | currency : 'USD' : 'symbol' : '1.0-2' }} a month if you find our product helpful. You can cancel
        anytime, and every new member gets a free DA {{ DA }}+ backlink ($500 value) and a free meeting with one of our
        Growth Experts. Every PRO account also lets you add up to 30 websites at no additional costs. If the free
        package isn’t meeting your needs, please try out our Welcome Package! It includes a lot more keyword,
        competitor, and backlink data and is 10x more likely to identify current ranking opportunities.
      </p>
    </ng-template>
  </app-faq-list-item>

  <app-faq-list-item>
    <ng-template #header>
      <span>What does Diib do with my data and is Diib GDPR compliant?</span>
    </ng-template>

    <ng-template #body>
      <p>
        Diib is GDPR compliant (we have 30k plus EU members) and even beyond this we simply believe that your data is
        your data. As such, our platform only accesses your data when you log into your dashboard and for the purpose of
        building you a custom Growth Plan. We only store generalized and aggregated data for benchmarking purposes. We
        do not store any specific data from your website on our servers. If you unsync a data source from Diib we will
        delete any stored generalized and/or aggregated data. We never share, sell, or trade any information about you
        or your specific website with any other company or individual. We take your privacy very seriously.
      </p>
      <p>
        If you’d like to remove yourself entirely from our system simply email us at
        <a href="mailto:support@diib.com">support&#64;diib.com</a> or contact us inside your dashboard and all information
        about you will be removed from our system including your name and email address.
      </p>
    </ng-template>
  </app-faq-list-item>

  <app-faq-list-item>
    <ng-template #header>
      <span>What type of support and customer service does Diib offer?</span>
    </ng-template>

    <ng-template #body>
      Every PRO member has free access to the <b>Diib Growth Experts</b>. To reach a Growth Expert, simply send an email
      to <a href="mailto:support@diib.com">support&#64;diib.com</a> and we will reach out to you to get a time scheduled.
      Each one of our Growth Experts has 10+ years of SEM/SEO marketing experience. We also have free
      <b>Platform Experts</b> that specialize in most website types and advertising platforms and can help you with
      things like getting your business ranking higher in maps, setting up PPC campaigns, updating an outdated website,
      etc... Our experts only goal is to help you grow faster. We never try to upsell you any services and if we
      recommend an additional service or expert we do not generally collect any referral fees. If we do have a referral
      partner where Diib benefits (rare but possible, i.e. an SSL provider or hosting company) we will make it clear to
      you that we are receiving a benefit.
      <b>As of {{ actualDate | date }} we have no referral agreements with any other product or service provider</b>.
    </ng-template>
  </app-faq-list-item>

  <app-faq-list-item>
    <ng-template #header>
      <span>Do you offer special pricing for Nonprofits?</span>
    </ng-template>

    <ng-template #body>
      Yes, we appreciate nonprofits and the good work they do for all of our communities around the globe. Simply create
      an account and then contact us prior to upgrading. After a quick review of your website one of our customer
      success representatives will be able to offer you our most current discounted nonprofit rates.
    </ng-template>
  </app-faq-list-item>

  <app-faq-list-item #list>
    <ng-template #header>
      <span>How do I cancel my account?</span>
    </ng-template>

    <ng-template #body>
      <p>
        If you are a PRO member simply remove your credit card from inside your account. This can be accessed under the
        “billing” tab at the top of your dashboard. You will immediately be switched over to a free account. If you’d
        like to also remove your website and any generalized stored data simply click on the “websites” tab at the top
        and delete any or all of the websites you no longer want us to track.
      </p>
      <p>
        If you want to remove every piece of information we have about you (for instance name and email), simply email
        us at <a href="mailto:support@diib.com">support&#64;diib.com</a>,
        <a href="mailto:hello@diib.com">hello&#64;diib.com</a>, or through any contact us link in your dashboard and let us
        know to delete all information or remove you from Diib. How sad! Like we never even met you :) but we
        understand.
      </p>
    </ng-template>
  </app-faq-list-item>
</app-faq-list>
