import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AuthProvider } from '../../../common/models/auth-provider.model';
import { AuthPath } from '../../routes';

@Component({
  selector: 'app-notification-state',
  templateUrl: './notification-state.component.html',
  styleUrls: ['./notification-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class NotificationStateComponent {
  @Input() authProvider: AuthProvider;

  readonly AuthPath = AuthPath;
  protected readonly AuthProvider = AuthProvider;
}
