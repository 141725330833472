import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FeaturedMemberService } from '../core/services';

@Injectable({ providedIn: 'root' })
export class FeaturedMemberGuardService {
  constructor(
    private router: Router,
    private featuredMemberService: FeaturedMemberService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.featuredMemberService
      .getStatus()
      .pipe(map(({ visited }) => (visited ? this.router.parseUrl('/dashboard') : true)));
  }
}
