import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { FacebookPixelEvents } from './common';
import {
  AuthService,
  CrazyEggService,
  FBPixelService,
  HubspotService,
  MauticService,
  StorageService,
} from './core/services';
import { environment } from '../environments/environment';
import { AppState } from './dashboard/_state/state';
import { loadUser } from './state/user/actions';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private store: Store<AppState>,
    private pixel: FBPixelService,
    private hubspotService: HubspotService,
    private crazyEggService: CrazyEggService,
    private localStorage: StorageService,
    private mauticService: MauticService,
  ) {}

  ngOnInit() {
    this.hubspotService.init();
    this.mauticService.init();
    this.crazyEggService.init();
    this.getUserIfTokenIsPresent();

    this.route.queryParams.subscribe((params: Params) => {
      if (params.auth_token) {
        this.auth.setToken({ token: params.auth_token as string });
        this.clearAuthParam();
      }
    });

    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        distinctUntilChanged((previous: NavigationEnd, current: NavigationEnd) => {
          return previous.url !== current.url;
        }),
        untilDestroyed(this),
      )
      .subscribe((event: NavigationEnd) => {
        this.emitPageViewEvent(event);
        this.hubspotService.trackPage(event.url);
        this.mauticService.trackPage();
      });

    this.enableGoogleOptimize();
  }

  private clearAuthParam(): void {
    this.router.navigate(['.'], { relativeTo: this.route, queryParams: this.route.snapshot.queryParams });
  }

  private enableGoogleOptimize(): void {
    const envName = environment.name;

    if (envName === 'prod' || envName === 'staging') {
      if ((window as any).dataLayer) {
        (window as any).dataLayer.push({ event: 'optimize.activate' });
      }
    }
  }

  private emitPageViewEvent(event: NavigationEnd): void {
    /* List of urls that should be filtered to avoid sending multiple page hit events in GA.
     *
     * Example:
     * /pricing --> /pricing/regular */
    const urlsWithWildcards = ['/pricing', '/dashboard/services/pricing', '/keywords/competitors'];

    if (!urlsWithWildcards.includes(event?.url)) {
      this.pixel.track(FacebookPixelEvents.PageView);
    }
  }

  private getUserIfTokenIsPresent(): void {
    const token = this.localStorage.readData('auth_jwt_token');

    if (token) {
      this.store.dispatch(loadUser());
    }
  }
}
