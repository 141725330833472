import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, tap } from 'rxjs/operators';

import { logout } from './user/actions';
import { COUNT_WRONG_ACCOUNT_MODAL_WAS_OPENED, USER_ID } from '../common/constants/storage';
import { StorageService } from '../core/services';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private localStorage: StorageService,
  ) {}

  clearLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        tap(() => {
          this.handleLocalStorageOnLogout();
        }),
        catchError((error) => {
          console.error(`[AppEffect] Failed to clear local storage on logout`);

          return error;
        }),
      ),
    { dispatch: false },
  );

  private handleLocalStorageOnLogout() {
    const { userId, count } = this.getUserData();

    this.localStorage.removeAll();

    this.updateLocalStorage(userId, count);
  }

  private updateLocalStorage(userId: string | null, count: string | null): void {
    if (userId) {
      this.localStorage.writeData(USER_ID, userId);
    }

    if (count) {
      this.localStorage.writeData(COUNT_WRONG_ACCOUNT_MODAL_WAS_OPENED, count);
    }
  }

  private getUserData(): { userId: string | null; count: string | null } {
    const userId = this.localStorage.readData(USER_ID);
    const count = this.localStorage.readData(COUNT_WRONG_ACCOUNT_MODAL_WAS_OPENED);

    return { userId, count };
  }
}
