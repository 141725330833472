import { HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { StorageService } from '../../core/services';

@Component({
  selector: 'app-signup-ga-sync',
  templateUrl: 'signup-ga-sync.component.html',
  styleUrls: ['signup-ga-sync.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SignupGaSyncComponent implements OnInit, OnDestroy {
  domain: string | null = null;
  authUrl: string | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storage: StorageService,
  ) {}

  ngOnInit() {
    this.domain = this.route.snapshot.queryParams.domain;
    this.authUrl = this.route.snapshot.queryParams.authUrl;
  }

  ngOnDestroy(): void {
    this.storage.removeDataByKey('domain');
    this.storage.removeDataByKey('siteId');
  }

  importGA(): void {
    if (this.authUrl) {
      // 'signup' flag is needed for GA select view on dashboard
      const params = new HttpParams({ fromString: this.authUrl }).append('signup', true);

      location.assign(params.toString());
    }
  }

  skipImportGA(): void {
    this.router.navigateByUrl(`/dashboard`);
  }
}
