import { Industry } from '../../common/models';

export interface IndustriesState {
  industries: Industry[] | null;
}

const defaultIndustriesState: IndustriesState = {
  industries: null,
};

export const DEFAULT_INDUSTRIES_STATE: IndustriesState = defaultIndustriesState;
