import { SocialUser } from '@abacritt/angularx-social-login';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SignupResponse } from '@common/models';
import { SocialLoginResponseModel } from '@common/models/login-response.model';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { iif, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize } from 'rxjs/operators';

import { NotificationService } from '../../_notification/notification.service';
import { Chambers } from '../../chambers/chamber';
import { extractHttpErrors, hasErrorWithKey } from '../../common';
import { CHAMBER_ID, IS_SIGNED_UP } from '../../common/constants/storage';
import { InvalidCaptchaError } from '../../common/errors/invalid-captcha';
import { FailedResponseNotParsedError } from '../../common/errors/signup-errors';
import { AuthProvider } from '../../common/models/auth-provider.model';
import { AuthService, StorageService } from '../../core/services';
import { MauticService } from '../../core/services/mautic.service';
import { GAEventCategory, GtmService } from '../../services/gtm.service';
import { AppState } from '../../state/reducers';
import { selectUserEmail, selectUserId } from '../../state/user/selectors';

interface SignUpForm {
  fullName: FormControl<string>;
  email: FormControl<string>;
  password: FormControl<string>;
}

/**
 * INFO:
 * At the start, if domain and siteId are detected, component starts polling GA detection status
 * and polls it until status code 200 is returned. If result is not detected before signup is finished it should stop polling.
 */
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  standalone: false,
})
export class SignupComponent implements OnInit, OnDestroy {
  readonly form: FormGroup<SignUpForm> = this.initForm();
  readonly userId$: Observable<number> = this.store.select(selectUserId).pipe(filter(Boolean));
  readonly userEmail$: Observable<string> = this.store.select(selectUserEmail);

  loading: boolean = false;
  submitting: boolean = false;
  accountCreated: boolean = false;
  isGoogleApiLoaded$ = this.authService.isGoogleApiLoaded$;

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: NonNullableFormBuilder,
    private route: ActivatedRoute,
    private notification: NotificationService,
    private gtmService: GtmService,
    private store: Store<AppState>,
    private localStorage: StorageService,
    private mauticService: MauticService,
  ) {}

  ngOnInit() {
    this.gtmService.emitGTMEvent({ eventName: 'sign_up_initiated', category: GAEventCategory.Enrollment });
    this.authService.startGADetection(this.route);
  }

  ngOnDestroy(): void {
    this.authService.stopGADetection();
  }

  submit(): void {
    this.notification.hide();
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.finishSubmit();
  }

  private finishSubmit(): void {
    const email = this.form.value.email ? this.form.value.email.toLowerCase() : '';
    const userData = { ...this.form.value, email, agree: true };
    const { ref } = this.route.snapshot.queryParams;
    const body = { site: this.siteId, signup: userData, ref };

    this.submitting = true;
    this.mauticService.trackContacts(email, userData.fullName || '');

    this.authService
      .signUp(AuthProvider.EMAIL, body, 0)
      .pipe(finalize(() => (this.submitting = false)))
      .subscribe({
        next: (data: SignupResponse) => {
          this.accountCreated = true;
          this.authService.setToken({ token: data.token });
          this.authService.trackSuccessfulSignUp(this.domain, data.id, email);
          this.setFlagForSignUp();
          this.navigateAfterSignUp(data.authUrl);
        },
        error: (response: HttpErrorResponse) => {
          try {
            const errors: string[] = extractHttpErrors(response);

            if (hasErrorWithKey('gCaptchaKey', response)) {
              Sentry.captureException(new InvalidCaptchaError(response));

              const idx = errors.findIndex((e) => e.toLowerCase() === 'invalid captcha.');

              if (idx || idx === 0) {
                errors[idx] =
                  '\nFailed to connect Google services. Please make sure you are connected to internet and nothing blocks access to Google.com and then try again.';
              }
            }

            this.notification.show(errors.length ? errors.join('\n') : 'Something went wrong', 'danger');
          } catch (error) {
            console.error('[SignupComponent] Failed to SignUp with email', error);
            Sentry.captureException(new FailedResponseNotParsedError(response));
            this.notification.show('Something went wrong. Please try again later or contact our support.', 'danger');
          }
        },
      });
  }

  onUserReceivedFromFacebook(user: SocialUser): void {
    this.submitting = true;
    const payload = { ...this.route.snapshot.queryParams };

    if (this.siteId) {
      payload['site'] = `${this.siteId}`;
    }

    this.authService
      .signUp(AuthProvider.FACEBOOK, user, payload)
      .pipe(
        finalize(() => (this.submitting = false)),
        catchError((err: HttpErrorResponse) =>
          iif(
            () => err.status === (HttpStatusCode.Conflict as number),
            this.authService.signIn(AuthProvider.FACEBOOK, user, payload),
            throwError(() => err),
          ),
        ),
      )
      .subscribe({
        next: (r: SocialLoginResponseModel) => {
          this.authService.setToken({ token: r.token });
          this.mauticService.trackContacts(r.email || '', r.firstName || '');
          this.navigateAfterOAuth2SignUp(r.authUrl);

          if (r.email) {
            this.authService.trackSuccessfulSignUp(this.domain, r.id, r.email);
          }
        },
        error: (response: HttpErrorResponse) => {
          Sentry.captureException(response);
          this.notification.show(response.error ? response.error.message : 'Something went wrong', 'danger');
        },
      });
  }

  onSignUpWithGoogle(token: string): void {
    this.submitting = true;
    const payload = { ...this.route.snapshot.queryParams };

    if (this.siteId) {
      payload['site'] = `${this.siteId}`;
    }

    this.authService
      .signUp(AuthProvider.GOOGLE, token, payload)
      .pipe(
        finalize(() => (this.submitting = false)),
        catchError((err: HttpErrorResponse) =>
          iif(
            () => err.status === (HttpStatusCode.Conflict as number),
            this.authService.signIn(AuthProvider.GOOGLE, token, payload),
            throwError(() => err),
          ),
        ),
      )
      .subscribe({
        next: (r: SocialLoginResponseModel) => {
          this.authService.setToken({ token: r.token });
          this.mauticService.trackContacts(r.email || '', r.firstName || '');
          this.navigateAfterOAuth2SignUp(r.authUrl);

          if (r.email) {
            this.authService.trackSuccessfulSignUp(this.domain, r.id, r.email);
          }
        },
        error: (response: HttpErrorResponse) => {
          if (response.status !== (HttpStatusCode.Unauthorized as number)) {
            Sentry.captureException(response);
          }

          this.notification.show(response.error ? response.error.message : 'Something went wrong', 'danger');
        },
      });
  }

  private navigateAfterSignUp(authUrl: string | null): void {
    const chamberId = this.localStorage.readData(CHAMBER_ID);

    this.loading = false;

    if (!this.siteId || !authUrl || authUrl === 'error') {
      this.router.navigateByUrl(`/import`);
    }

    if (this.authService.isGADetected && !chamberId) {
      this.router.navigate(['signup-ga-sync'], { queryParams: { domain: this.domain, authUrl: authUrl } });
    } else {
      const siteId = this.localStorage.readData('siteId');
      const isChamberIdValid = !!chamberId && Object.values<string>(Chambers).includes(chamberId);

      if (isChamberIdValid && siteId) {
        this.router.navigateByUrl(`/chambers/${chamberId}`);
      } else {
        this.router.navigateByUrl('/dashboard');
      }
    }
  }

  /**
   * Decides where to redirect user after sign up is finished successfully.
   *
   * @param authUrl - contains url for authentication of GA for current siteId. Is required when GA was detected.
   * @param isNewUser
   */
  private navigateAfterOAuth2SignUp(authUrl: string | null): void {
    const chamberId = this.localStorage.readData(CHAMBER_ID);

    this.loading = false;

    if (!this.siteId || !authUrl || authUrl === 'error') {
      this.router.navigateByUrl(`/import`);
    }

    if (this.authService.isGADetected && !chamberId) {
      this.router.navigate(['signup-ga-sync'], { queryParams: { domain: this.domain, authUrl: authUrl } });
    } else {
      const siteId = this.localStorage.readData('siteId');
      const isChamberIdValid = !!chamberId && Object.values<string>(Chambers).includes(chamberId);

      if (isChamberIdValid && siteId) {
        this.router.navigateByUrl(`/chambers/${chamberId}`);
      } else {
        this.router.navigateByUrl('/dashboard');
      }
    }
  }

  private initForm(): FormGroup<SignUpForm> {
    return this.fb.group<SignUpForm>({
      fullName: this.fb.control<string>('', [Validators.required, Validators.maxLength(100)]),
      email: this.fb.control<string>('', [Validators.required, Validators.email]),
      password: this.fb.control<string>('', [Validators.required, Validators.minLength(8)]),
    });
  }

  private setFlagForSignUp(): void {
    this.localStorage.writeData(IS_SIGNED_UP, true);
  }

  get fullName(): FormControl<string> {
    return this.form.controls.fullName;
  }

  get email(): FormControl<string> {
    return this.form.controls.email;
  }

  get password(): FormControl<string> {
    return this.form.controls.password;
  }

  get isGADetected(): boolean {
    return this.authService.isGADetected;
  }

  get domain(): string | null {
    return this.authService.getDomain(this.route);
  }

  get siteId(): number | null {
    return this.authService.getSiteId();
  }
}
