import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthPath } from './routes';
import { SignupComponent } from './signup/signup.component';
import { SignupGaSyncComponent } from './signup-ga-sync/signup-ga-sync.component';
import { SwitchComponent } from './switch/switch.component';

const routes: Routes = [
  {
    path: AuthPath.Login,
    title: 'Sign In | Diib™ Inc.',
    component: LoginComponent,
  },
  {
    path: AuthPath.Signup,
    title: 'Sign Up | Diib™ Inc.',
    component: SignupComponent,
  },
  {
    path: AuthPath.SignupGaSync,
    title: 'Connect GA | Diib™ Inc.',
    component: SignupGaSyncComponent,
  },
  {
    path: AuthPath.Switch,
    component: SwitchComponent,
  },
  {
    path: AuthPath.ForgotPassword,
    title: 'Forgot Password | Diib™ Inc.',
    component: ForgotPasswordComponent,
  },
  {
    path: `${AuthPath.ResetPassword}/:token`,
    title: 'Reset Password | Diib™ Inc.',
    component: ResetPasswordComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
