import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import {
  ArcElement,
  CategoryScale,
  Chart,
  Colors,
  DoughnutController,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { version } from './environments/version';

Sentry.init({
  enabled: environment.sentryEnabled,
  dsn: environment.sentryDsn,
  environment: environment.name,
  release: `answerengine-frontend@${version.hash}`,
  autoSessionTracking: true,
  integrations: [Sentry.breadcrumbsIntegration({ console: false })],
  tracesSampleRate: 0.5,
  beforeSend(event) {
    // prevents sending multiple ChunkLoadErrors
    if (event.exception && event.exception.values?.[0].type === 'ChunkLoadError') {
      return null;
    }

    return event;
  },
});

if (environment.production) {
  enableProdMode();
}

// Registers "Chart.js" controlls globally
Chart.register(
  DoughnutController,
  LineController,
  Tooltip,
  ArcElement,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Colors,
  Filler,
);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
