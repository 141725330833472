import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

import { billingReducer } from './billing/reducer';
import { BillingState } from './billing/state';
import { countriesReducer, CountriesState } from './countries/reducers';
import { globalReducer, GlobalState } from './global.reducer';
import { industriesReducer } from './industries/reducer';
import { IndustriesState } from './industries/state';
import { metricsReducer } from './metrics/reducers';
import { DataValue } from './metrics/state';
import { RouterState } from './router-state';
import { logout } from './user/actions';
import { userReducer } from './user/reducers';
import { UserState } from './user/state';
import { websiteReducers } from './website/reducers';
import { websitesReducer } from './website-list/reducers';
import { WebsitesListState } from './website-list/state';
import { MetricsRootState } from '../common/models';

export interface AppState {
  router: RouterReducerState<RouterState>;
  global: GlobalState;
  user: UserState;
  website: any;
  websites: WebsitesListState;
  countries: CountriesState;
  billing: BillingState;
  metrics: MetricsRootState<DataValue>;
  industries: IndustriesState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  global: globalReducer,
  user: userReducer,
  website: websiteReducers,
  websites: websitesReducer,
  countries: countriesReducer,
  billing: billingReducer,
  metrics: metricsReducer,
  industries: industriesReducer,
};

const resetStateOnLogoutReducer =
  (reducer: ActionReducer<AppState>): ActionReducer<AppState> =>
  (state, action) =>
    reducer(action.type === logout.type ? undefined : state, action);

export const metaReducers: MetaReducer[] = [resetStateOnLogoutReducer];
