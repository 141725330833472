import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { catchError, EMPTY, filter, map, of, switchMap } from 'rxjs';
import { delay, withLatestFrom } from 'rxjs/operators';

import {
  loadMetrics,
  loadMetricsFail,
  loadMetricsSuccess,
  retryLoadingMetric,
  stopMetricsRetry,
  updateState,
} from './actions';
import { selectEntityState, selectMetricsName, selectMetricsState } from './selectors';
import { DataValue } from './state';
import { EntityState } from '../../common';
import { MetricsRootState, MetricsState, MetricsStatus } from '../../common/models';
import { selectRecentWebsiteId } from '../../dashboard/_state/selectors';
import { AppState } from '../../dashboard/_state/state';
import { WebsiteService } from '../../services/website.service';

@Injectable()
export class MetricsEffects {
  private readonly websiteId$ = this.store.select(selectRecentWebsiteId).pipe(filter(Boolean));
  private readonly metricsName$ = this.store.select(selectMetricsName);
  private readonly entityState$ = this.store.select(selectEntityState);
  private readonly metricsState$ = this.store.select(selectMetricsState);

  loadMetrics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadMetrics),
      withLatestFrom(this.websiteId$, this.metricsName$, this.entityState$),
      switchMap(([{ metricsName }, websiteId, pendingMetricNames, entityState]) => {
        if (this.canActionBeProcessed(entityState)) {
          const metricsSet = new Set([...pendingMetricNames, ...metricsName]);
          const metrics = Array.from(metricsSet);

          return this.websiteService.getMetrics(metrics, websiteId).pipe(
            withLatestFrom(this.entityState$),
            map(([data, entityState]) => ({
              metricsState: data.body as MetricsState<DataValue>,
              metricsName: metrics,
              entityState,
            })),
            catchError((response: HttpErrorResponse) => {
              console.error(response);

              Sentry.captureException(
                { reason: JSON.stringify(response) },
                {
                  tags: {
                    siteId: websiteId,
                    statusCode: response.status,
                    statusText: response.statusText,
                    reason: 'Something went wrong with metric endpoint',
                  },
                },
              );

              return of(null);
            }),
          );
        }

        return EMPTY;
      }),
      map((data) => {
        if (!this.canActionBeProcessed(data?.entityState as EntityState)) {
          return loadMetricsFail();
        }

        if (data === null) {
          return loadMetricsFail();
        }

        if (data.metricsName.length === 0) {
          return loadMetricsSuccess({ metricsState: data.metricsState });
        }

        const metricsToFetch = data.metricsName.filter(
          (name) =>
            data.metricsState[name].state === MetricsStatus.InProgress ||
            data.metricsState[name].state === MetricsStatus.Error,
        );

        return metricsToFetch.length
          ? updateState({ metricsName: metricsToFetch, metricsState: data.metricsState })
          : loadMetricsSuccess({ metricsState: data.metricsState });
      }),
      catchError((response) => {
        console.error(response);

        return of(loadMetricsFail());
      }),
    );
  });

  updateState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateState),
      concatLatestFrom(() => this.entityState$),
      filter(([, entityState]) => this.canActionBeProcessed(entityState)),
      map(() => retryLoadingMetric()),
    );
  });

  loadMetricsRetry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(retryLoadingMetric),
      delay(1500),
      withLatestFrom(this.metricsName$, this.entityState$),
      filter(([, , entityState]) => this.canActionBeProcessed(entityState)),
      map(([, metricsName]) => loadMetrics({ metricsName })),
    );
  });

  stopRetryingMetrics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stopMetricsRetry),
      concatLatestFrom(() => this.metricsState$),
      map((state) => {
        const [, data] = state;

        return updateState(this.getStateWithUnfinishedMetricsDisabled(data));
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private websiteService: WebsiteService,
  ) {}

  private canActionBeProcessed(entityState: EntityState): boolean {
    return entityState !== EntityState.Loaded;
  }

  /**
   * Goes throw metrics that are still being fetched and sets their 'state' to 'Error' to prevent further fetching.
   */
  private getStateWithUnfinishedMetricsDisabled = (state: MetricsRootState<DataValue>) => {
    const { metricsName, metricsState } = state;
    const metrics = metricsName
      .map((metric) => ({
        [metric]: {
          state: MetricsStatus.Error,
          data: null,
        },
      }))
      .reduce(
        (acc, current) => ({
          ...acc,
          ...current,
        }),
        {},
      );

    return {
      metricsName: [],
      metricsState: {
        ...metricsState,
        ...metrics,
      },
    };
  };
}
