import {
  BacklinksDTO,
  BacklinksTotalDTO,
  CompetitorBacklinkOverviewDTO,
  CompetitorDomainAuthorityDTO,
  CompetitorKeywordsTotalDTO,
  CompetitorsData,
  CoreVitalsDTO,
  DashboardBacklinks,
  DashboardFBPage30days,
  DashboardFBPage7days,
  DashboardFBPage90days,
  DashboardFBPosts30days,
  DashboardFBPosts7days,
  DashboardFBPosts90days,
  DashboardGAGeneral30days,
  DashboardGAGeneral7days,
  DashboardGAGeneral90days,
  DashboardGBPInsights30days,
  DashboardGBPInsights7days,
  DashboardGBPInsights90days,
  DashboardGBPReviews30days,
  DashboardGBPReviews7days,
  DashboardGBPReviews90days,
  DashboardIndustry30days,
  DashboardIndustry7days,
  DashboardIndustry90days,
  DashboardKeywords,
  DomainAuthorityDTO,
  EmailMetricDTO,
  FocusItemDTO,
  IndustryDTO,
  KeywordsDTO,
  LocalBoostDTO,
  MetricsRootState,
  MetricsStatus,
  SitemapDTO,
  SSLMetricDTO,
  TrafficChartDTO,
} from '@common/models';

import { EntityState } from '../../common';

export interface DataValue {
  ssl: SSLMetricDTO | null;
  sitemap: SitemapDTO | null;
  backlinksTotal: BacklinksTotalDTO | null;
  domainOverview: KeywordsDTO | null;
  domainAuthorityOverview: DomainAuthorityDTO | null;
  emailBlacklist: EmailMetricDTO | null;
  emailSPF: EmailMetricDTO | null;
  emailDMARC: EmailMetricDTO | null;
  corevitals: CoreVitalsDTO | null;
  annualGrowthOpportunity: null;
  websiteRank: null;
  competitorsBacklinksOverview: CompetitorsData<CompetitorBacklinkOverviewDTO> | null;
  competitorsDomainOverview: CompetitorsData<CompetitorKeywordsTotalDTO> | null;
  competitorsDomainAuthority: CompetitorsData<CompetitorDomainAuthorityDTO> | null;
  focusItemViews: FocusItemDTO | null;
  focusItemSubmissions: FocusItemDTO | null;
  totalTraffic: FocusItemDTO | null;
  trafficChart: TrafficChartDTO | null;
  industry: IndustryDTO | null;
  backlinks: BacklinksDTO | null;
  localBoost: LocalBoostDTO | null;
  dashboardGAGeneral7days: DashboardGAGeneral7days | null;
  dashboardGAGeneral30days: DashboardGAGeneral30days | null;
  dashboardGAGeneral90days: DashboardGAGeneral90days | null;
  dashboardDailyHealthScore: null;
  dashboardFBPage7days: DashboardFBPage7days | null;
  dashboardFBPage30days: DashboardFBPage30days | null;
  dashboardFBPage90days: DashboardFBPage90days | null;
  dashboardFBPosts7days: DashboardFBPosts7days | null;
  dashboardFBPosts30days: DashboardFBPosts30days | null;
  dashboardFBPosts90days: DashboardFBPosts90days | null;
  dashboardIndustry7days: DashboardIndustry7days | null;
  dashboardIndustry30days: DashboardIndustry30days | null;
  dashboardIndustry90days: DashboardIndustry90days | null;
  dashboardGBPInsights7days: DashboardGBPInsights7days | null;
  dashboardGBPInsights30days: DashboardGBPInsights30days | null;
  dashboardGBPInsights90days: DashboardGBPInsights90days | null;
  dashboardGBPReviews7days: DashboardGBPReviews7days | null;
  dashboardGBPReviews30days: DashboardGBPReviews30days | null;
  dashboardGBPReviews90days: DashboardGBPReviews90days | null;
  dashboardBacklinks: DashboardBacklinks | null;
  dashboardKeywords: DashboardKeywords | null;
}

const defaultMetricsState: MetricsRootState<DataValue> = {
  /**
   * Represent overal state of Metrics fetching. Can be set to LOADED only when all metrics are either loaded
   * or their fetching is intentionally stopped.
   */
  entityState: EntityState.NotLoaded,
  /**
   * List of metrics that are currently being fetched
   *
   * */
  metricsName: [],
  metricsState: {
    ssl: {
      state: MetricsStatus.Default,
      data: null,
    },
    sitemap: {
      state: MetricsStatus.Default,
      data: null,
    },
    backlinksTotal: { state: MetricsStatus.Default, data: null },
    domainOverview: {
      state: MetricsStatus.Default,
      data: null,
    },
    domainAuthorityOverview: { state: MetricsStatus.Default, data: null },
    emailBlacklist: { state: MetricsStatus.Default, data: null },
    emailSPF: { state: MetricsStatus.Default, data: null },
    emailDMARC: { state: MetricsStatus.Default, data: null },
    corevitals: { state: MetricsStatus.Default, data: null },
    annualGrowthOpportunity: { state: MetricsStatus.Default, data: null },
    websiteRank: { state: MetricsStatus.Default, data: null },
    competitorsBacklinksOverview: { state: MetricsStatus.Default, data: null },
    competitorsDomainOverview: { state: MetricsStatus.Default, data: null },
    competitorsDomainAuthority: { state: MetricsStatus.Default, data: null },
    focusItemViews: { state: MetricsStatus.Default, data: null },
    focusItemSubmissions: { state: MetricsStatus.Default, data: null },
    totalTraffic: { state: MetricsStatus.Default, data: null },
    trafficChart: { state: MetricsStatus.Default, data: null },
    industry: { state: MetricsStatus.Default, data: null },
    backlinks: { state: MetricsStatus.Default, data: null },
    localBoost: { state: MetricsStatus.Default, data: null },
    dashboardDailyHealthScore: { state: MetricsStatus.InProgress, data: null },
    dashboardGAGeneral7days: { state: MetricsStatus.InProgress, data: null },
    dashboardGAGeneral30days: { state: MetricsStatus.InProgress, data: null },
    dashboardGAGeneral90days: { state: MetricsStatus.InProgress, data: null },
    dashboardIndustry7days: { state: MetricsStatus.InProgress, data: null },
    dashboardIndustry30days: { state: MetricsStatus.InProgress, data: null },
    dashboardIndustry90days: { state: MetricsStatus.InProgress, data: null },
    dashboardGBPInsights7days: { state: MetricsStatus.InProgress, data: null },
    dashboardGBPInsights30days: { state: MetricsStatus.InProgress, data: null },
    dashboardGBPInsights90days: { state: MetricsStatus.InProgress, data: null },
    dashboardGBPReviews7days: { state: MetricsStatus.InProgress, data: null },
    dashboardGBPReviews30days: { state: MetricsStatus.InProgress, data: null },
    dashboardGBPReviews90days: { state: MetricsStatus.InProgress, data: null },
    dashboardFBPage7days: { state: MetricsStatus.InProgress, data: null },
    dashboardFBPage30days: { state: MetricsStatus.InProgress, data: null },
    dashboardFBPage90days: { state: MetricsStatus.InProgress, data: null },
    dashboardFBPosts7days: { state: MetricsStatus.InProgress, data: null },
    dashboardFBPosts30days: { state: MetricsStatus.InProgress, data: null },
    dashboardFBPosts90days: { state: MetricsStatus.InProgress, data: null },
    dashboardBacklinks: { state: MetricsStatus.Default, data: null },
    dashboardKeywords: { state: MetricsStatus.Default, data: null },
  },
};

export const DEFAULT_METRICS_STATE = defaultMetricsState;
