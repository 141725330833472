import { Injectable } from '@angular/core';
import { Country } from '@common/models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';

import { loadAllCountries, loadAllCountriesFail, loadAllCountriesSuccess } from './actions';
import { selectAllCountries } from './selectors';
import { AppState } from '../../dashboard/_state/state';
import { SeoService } from '../../services/seo.service';

@Injectable()
export class CountriesEffects {
  loadAllCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllCountries),
      concatLatestFrom(() => this.store.select(selectAllCountries)),
      switchMap(([, countries]) => (!countries.length ? this.seoService.getCountries() : of(countries))),
      map((countries: Country[]) => loadAllCountriesSuccess({ countries })),
      catchError((response) => {
        console.error(response);

        return of(loadAllCountriesFail({ error: response.error }));
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private seoService: SeoService,
  ) {}
}
