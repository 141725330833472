import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-google-auth-button',
  template: ` <div #googleAuthBtn id="google-auth-btn" class="button"></div>`,
  styles: [
    `
      :host-context(.button--last) .button {
        margin-top: 4px;
        margin-bottom: 22px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GoogleAuthButtonComponent implements AfterViewInit {
  @Input() text: 'signin_with' | 'signup_with' = 'signin_with';

  @Output() tokenReceived = new EventEmitter<string>();

  @ViewChild('googleAuthBtn') button: ElementRef;

  constructor(private ngZone: NgZone) {}

  ngAfterViewInit(): void {
    this.renderButton();
  }

  private renderButton(): void {
    google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: (r) => this.ngZone.run(() => this.handleCredentialResponse(r)),
    });

    google.accounts.id.renderButton(this.button.nativeElement, {
      type: 'standard',
      theme: 'outline',
      size: 'large',
      locale: 'en-US',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      width: 270,
      text: this.text,
    });
  }

  private handleCredentialResponse(response: google.accounts.id.CredentialResponse): void {
    const token = response.credential;

    this.tokenReceived.emit(token);
  }
}
