import { HttpErrorResponse } from '@angular/common/http';

export class FailedResponseNotParsedError extends Error {
  protected response: HttpErrorResponse;

  constructor(response: HttpErrorResponse) {
    super('Failed to parse error response from server');
    this.response = response;

    Object.setPrototypeOf(this, FailedResponseNotParsedError.prototype);
  }
}
