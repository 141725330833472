import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTES } from '@common/routes';
import { Store } from '@ngrx/store';

import { AuthService } from '../../core/services';
import { AppState } from '../../state/reducers';
import { loadUser } from '../../state/user/actions';
import { loadWebsites } from '../../state/website-list/actions';

/**
 * INFO: This component and it's route are used for authorization (impersonation) via ACP.
 * Do not remove it (!) or otherwise Support department won't be able to sign in to the app'.
 */
@Component({
  selector: 'app-switch',
  template: `<p>token is missing</p>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SwitchComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private auth: AuthService,
  ) {}

  ngOnInit(): void {
    const auth_token: string | undefined = this.route.snapshot.queryParams.auth_token as string;

    if (auth_token) {
      this.auth.setToken({ token: auth_token, acp: true });

      this.store.dispatch(loadUser());
      this.store.dispatch(loadWebsites());

      this.router.navigateByUrl(ROUTES.DASHBOARD);
    }
  }
}
