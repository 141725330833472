<div class="pad">
  <header #header>
    <a href="https://diib.com" class="logo">
      <img src="assets/images/logos/diib/logo-green.svg" alt="Logo" decoding="async" />
    </a>

    <div class="status">
      <ng-content select="[status]"></ng-content>
    </div>
  </header>
</div>

<div class="card-wrapper">
  <div class="card">
    <ng-content></ng-content>
  </div>
</div>

<div class="extra-wrapper">
  <ng-content select="[extra]"></ng-content>
</div>
