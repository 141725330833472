import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { StorageService } from '../../core/services';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AuthLayoutComponent implements OnInit {
  @ViewChild('header', { static: true }) header: ElementRef<HTMLElement>;

  domain: string | null;

  constructor(
    private storage: StorageService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.domain = this.getDomain();
  }

  private getDomain(): string | null {
    return this.storage.readData('domain') || this.route.snapshot.paramMap.get('domain');
  }
}
