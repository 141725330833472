import { SocialUser } from '@abacritt/angularx-social-login';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginResponseModel, SocialLoginResponseModel } from '@common/models/login-response.model';
import * as Sentry from '@sentry/angular';
import { finalize } from 'rxjs/operators';

import { NotificationService } from '../../_notification/notification.service';
import { REDIRECT_URL } from '../../common/constants/storage';
import { AuthProvider } from '../../common/models/auth-provider.model';
import { AuthService, StorageService } from '../../core/services';
import { NotificationStateComponent } from '../components/notification-state/notification-state.component';

interface LoginForm {
  username: FormControl<string | null>;
  password: FormControl<string | null>;
}

/**
 * INFO:
 * OAuth: /login page allows only to sign in with existing account. If account was not found we show a warning message.
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: false,
})
export class LoginComponent implements OnInit {
  @ViewChild('facebookMessageContainer', { read: ViewContainerRef })
  facebookMessageContainer!: ViewContainerRef;
  @ViewChild('googleMessageContainer', { read: ViewContainerRef })
  googleMessageContainer!: ViewContainerRef;

  readonly formGroup: FormGroup<LoginForm> = this.initForm();
  readonly isGoogleApiLoaded$ = this.authService.isGoogleApiLoaded$;

  /** Is needed to show a loader on the /login page when user is authorized and will be redirected soon */
  initializing: boolean = true;
  loading: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private storage: StorageService,
    private authService: AuthService,
    private notification: NotificationService,
  ) {}

  ngOnInit() {
    const passwordReset = this.route.snapshot.queryParams.passwordReset;

    if (passwordReset) {
      this.initializing = false;
      this.notification.show('Password has been changed. You can now sign in using new password.', 'success');
    }

    if (this.authService.getJwtToken() && this.authService.getRefreshToken()) {
      this.navigateAfterSignIn();
    } else {
      this.initializing = false;
    }
  }

  submit(): void {
    this.notification.hide();
    this.formGroup.markAllAsTouched();

    if (this.formGroup.valid) {
      const { username, password } = this.formGroup.value;

      this.loading = true;
      this.authService
        .signIn(AuthProvider.EMAIL, username, password)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe({
          next: (response: LoginResponseModel) => {
            const { token, refreshToken } = response;

            this.authService.setToken({ token, refreshToken });
            this.navigateAfterSignIn();
          },
          error: (error: { message: string }) => {
            this.notification.show(error.message, 'danger');
          },
        });
    }
  }

  onTokenReceivedFromGoogle(token: string): void {
    this.loading = true;
    const payload = { ...this.route.snapshot.queryParams };
    const siteId = this.authService.getSiteId();

    if (siteId) {
      payload['site'] = `${siteId}`;
    }

    this.authService
      .signIn(AuthProvider.GOOGLE, token, payload)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (r: SocialLoginResponseModel) => {
          this.authService.setToken({ token: r.token, refreshToken: r.refreshToken });
          this.navigateAfterSocialAuth(r.authUrl);
        },
        error: (response: HttpErrorResponse) => {
          if (response.status === (HttpStatusCode.Unauthorized as number)) {
            this.clearMessageContainers();
            const notificationRef = this.googleMessageContainer.createComponent(NotificationStateComponent);

            notificationRef.instance.authProvider = AuthProvider.GOOGLE;
          } else {
            Sentry.captureException(response);
            this.notification.show(response.error ? response.error.message : 'Something went wrong', 'danger');
          }
        },
      });
  }

  onUserReceivedFromFacebook(user: SocialUser): void {
    this.loading = true;
    const payload = { ...this.route.snapshot.queryParams };
    const siteId = this.authService.getSiteId();

    if (siteId) {
      payload['site'] = `${siteId}`;
    }

    this.authService
      .signIn(AuthProvider.FACEBOOK, user, payload)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (r: SocialLoginResponseModel) => {
          this.authService.setToken({ token: r.token, refreshToken: r.refreshToken });
          this.navigateAfterSocialAuth(r.authUrl);
        },
        error: (response: HttpErrorResponse) => {
          if (response.status === (HttpStatusCode.Unauthorized as number)) {
            this.clearMessageContainers();
            const notificationRef = this.facebookMessageContainer.createComponent(NotificationStateComponent);

            notificationRef.instance.authProvider = AuthProvider.FACEBOOK;
          } else {
            Sentry.captureException(response);
            this.notification.show(response.error ? response.error.message : 'Something went wrong', 'danger');
          }
        },
      });
  }

  navigateAfterSignIn(loginResult?: string): void {
    if (loginResult === 'error') {
      this.loading = false;

      return;
    }

    this.redirect();
  }

  navigateAfterSocialAuth(authUrl: string): void {
    const siteId = this.authService.getSiteId();

    if (!siteId || !authUrl || authUrl === 'error') {
      this.router.navigateByUrl(`/import`);
    }

    this.redirect();
  }

  private redirect(): void {
    const redirectUrl: string = this.storage.readData(REDIRECT_URL) || '/dashboard';

    this.router.navigateByUrl(redirectUrl).then(() => {
      this.loading = false;
      this.storage.removeDataByKey(REDIRECT_URL);
    });
  }

  private initForm(): FormGroup<LoginForm> {
    return this.fb.group<LoginForm>({
      username: this.fb.control<string | null>(null, [Validators.required]),
      password: this.fb.control<string | null>(null, [Validators.required]),
    });
  }

  private clearMessageContainers(): void {
    this.googleMessageContainer.clear();
    this.facebookMessageContainer.clear();
  }
}
