import { Country } from '@common/models';
import { createReducer, on } from '@ngrx/store';

import { loadAllCountriesFail, loadAllCountriesSuccess } from './actions';

export interface CountriesState {
  allCountries: Country[];
}

const allCountriesInitialState: CountriesState = {
  allCountries: [],
};

export const countriesReducer = createReducer(
  allCountriesInitialState,
  on(loadAllCountriesSuccess, (state, { countries }: { countries: Country[] }) => ({ allCountries: [...countries] })),
  on(loadAllCountriesFail, () => allCountriesInitialState),
);
