import { UserSubscriptionStatus } from '@common/models';
import { createReducer, on } from '@ngrx/store';

import {
  loadActiveSubscriptions,
  loadActiveSubscriptionsSuccess,
  loadHasProAccess,
  loadHasProAccessSuccess,
  loadPaymentMethod,
  loadPaymentMethodError,
  loadPaymentMethodSuccess,
  loadSubscription,
  loadSubscriptionSuccess,
  setSubscriptionStatus,
} from './actions';
import { DEFAULT_BILLING_STATE } from './state';
import { EntityState } from '../../common';

export const billingReducer = createReducer(
  DEFAULT_BILLING_STATE,
  on(loadHasProAccess, (state) => ({ ...state, proAccessState: EntityState.Loading })),
  on(loadHasProAccessSuccess, (state, { isProAccessProvided }) => ({
    ...state,
    isProAccessProvided,
    proAccessState: EntityState.Loaded,
  })),

  on(loadSubscription, (state) => ({ ...state })),
  on(loadSubscriptionSuccess, (state, { subscription }) => ({ ...state, subscription })),

  on(loadPaymentMethod, (state) => ({ ...state, billingState: EntityState.Loading })),
  on(loadPaymentMethodSuccess, (state, { paymentMethod }) => ({
    ...state,
    billing: {
      ...paymentMethod,
    },
    billingState: EntityState.Loaded,
  })),
  on(loadPaymentMethodError, (state) => ({
    ...state,
    billingState: EntityState.Error,
  })),

  on(loadActiveSubscriptions, (state) => ({ ...state })),
  on(loadActiveSubscriptionsSuccess, (state, { subscriptions }) => ({ ...state, subscriptions })),
  on(setSubscriptionStatus, (state, { subscriptionStatus }: { subscriptionStatus: UserSubscriptionStatus }) => ({
    ...state,
    subscription: state.subscription
      ? {
          ...state.subscription,
          status: subscriptionStatus,
        }
      : null,
  })),
);
