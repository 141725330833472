import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginResponseModel } from '@common/models/login-response.model';
import * as Sentry from '@sentry/angular';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { AuthService } from '../services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // FIXME: Request interceptor should not block requests
    const skip =
      !request.url.includes(environment.apiUrl) ||
      request.url.endsWith('/import/auth') ||
      request.url.includes('google/signin') ||
      request.url.includes('google/signup') ||
      request.url.includes('/pub/') ||
      request.url.includes('facebook/signin') ||
      request.url.includes('facebook/signup') ||
      request.url.endsWith(`/api/login_check`) ||
      request.url.endsWith(`/api/token/refresh`);

    if (skip) {
      return next.handle(request);
    }

    const jwtToken = this.authService.getJwtToken();
    if (jwtToken) {
      request = this.addToken(request);
    } else {
      this.authService.logout({ preserveUrl: true, reason: 'no_token' });

      return EMPTY;
    }

    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === (HttpStatusCode.Unauthorized as number)) {
          return this.handle401Error(request, next);
        }

        Sentry.captureException({
          reason: 'API request authentication failed',
          url: request.url,
          responseStatus: response.status,
        });

        return throwError(() => response);
      }),
    );
  }

  private addToken(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: { Authorization: 'Bearer ' + this.authService.getJwtToken() },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.authService.getRefreshToken()) {
      this.authService.logout({ preserveUrl: true, reason: '401' });

      return EMPTY;
    }

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((result: LoginResponseModel) => {
          this.isRefreshing = false;
          this.authService.setToken(result);
          this.refreshTokenSubject.next(result.token);

          return next.handle(this.addToken(request));
        }),
        catchError((response) => {
          Sentry.captureException({ reason: 'Attempt to refresh token failed' });
          this.authService.logout({ preserveUrl: true, reason: 'cannot refresh token' });

          return throwError(() => response);
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap(() => next.handle(this.addToken(request))),
      );
    }
  }
}
