import { ContactInfo, FeaturedMemberStatus, User, UserActivity } from '@common/models';

import { EntityState } from '../../common';

export interface UserState {
  user: User | null;
  userState: EntityState;
  featuredMemberStatus: FeaturedMemberStatus;
  contacts: ContactInfo | null;
  activity: UserActivity;
}

const defaultUserState: UserState = {
  user: null,
  userState: EntityState.NotLoaded,
  featuredMemberStatus: {
    visited: false,
    published: false,
    pageUrl: null,
  },
  contacts: null,
  activity: null,
};

export const DEFAULT_USER_STATE = defaultUserState;
