import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationService } from '../../_notification/notification.service';
import { PasswordValidator } from '../../common/validators/password.validator';
import { AuthService } from '../../core/services';

interface ResetPasswordForm {
  password: FormControl<null | string>;
  repeated: FormControl<null | string>;
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  standalone: false,
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup<ResetPasswordForm> = this.fb.group<ResetPasswordForm>({
    password: this.fb.control(null, [Validators.required, Validators.minLength(8)]),
    repeated: this.fb.control(null, [Validators.required]),
  });

  token: string = '';
  submitted: boolean = false;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private notification: NotificationService,
  ) {}

  ngOnInit() {
    this.token = this.route.snapshot.params.token;
    this.form.controls.repeated.setValidators(PasswordValidator.matchValidator(this.form.controls.password));
  }

  submit(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const password: string = this.form.value.password as string;

      this.auth.resetPassword(password, this.token).subscribe({
        next: () => {
          this.submitted = true;
          this.router
            .navigateByUrl('/login')
            .then(() =>
              setTimeout(() =>
                this.notification.show('Password has been changed. You can now sign in using new password.', 'success'),
              ),
            );
        },
        error: (response: HttpErrorResponse) => {
          this.notification.show(response.error.error, 'danger');
        },
      });
    }
  }

  get password(): FormControl<null | string> {
    return this.form.controls.password;
  }

  get repeated(): FormControl<null | string> {
    return this.form.controls.repeated;
  }
}
