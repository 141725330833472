import { ChangeDetectionStrategy, Component } from '@angular/core';

import { GLOBAL_USERS } from '../../../common';

// TODO: Very similar to shared/used-by but styles are different. Merge into one.
@Component({
  selector: 'app-signup-used-by',
  templateUrl: './used-by.component.html',
  styleUrls: ['./used-by.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UsedByComponent {
  readonly globalUsers = GLOBAL_USERS;
}
