import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '@core/services';
import { environment } from '@env/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
  connectChannel,
  connectChannelSuccess,
  dashboardPollingFinished,
  loadWebsite,
  loadWebsiteCountry,
  loadWebsiteCountryFail,
  loadWebsiteCountrySuccess,
  loadWebsiteFail,
  loadWebsiteSuccess,
  loadWebsiteSyncInfo,
  loadWebsiteSyncInfoFail,
  loadWebsiteSyncInfoSuccess,
  resumeObjective,
  skipObjective,
  syncChannel,
} from './actions';
import { selectWebsiteGeneral } from './selectors';
import { NotificationService } from '../../_notification/notification.service';
import { selectRecentWebsiteId } from '../../dashboard/_state/selectors';
import { AppState } from '../../dashboard/_state/state';
import { WebsiteService } from '../../services/website.service';
import { selectUserId } from '../user/selectors';

@Injectable()
export class WebsiteEffects {
  readonly websiteId$: Observable<number> = this.store.select(selectRecentWebsiteId).pipe(filter(Boolean));

  loadWebsite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadWebsite),
      switchMap(({ id }) =>
        this.service.getSite(id).pipe(
          map((website) => loadWebsiteSuccess({ website })),
          catchError(() => {
            return of(loadWebsiteFail());
          }),
        ),
      ),
    );
  });

  loadWebsiteSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loadWebsiteSuccess),
        tap(({ website }) => this.storage.writeData<number>('recentWebsiteId', website.id)),
      );
    },
    { dispatch: false },
  );

  loadWebsiteSyncInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadWebsiteSyncInfo),
      concatLatestFrom(() => this.websiteId$),
      switchMap(([, id]) =>
        this.service.getSyncInfo(id).pipe(
          map((syncInfo) => loadWebsiteSyncInfoSuccess({ syncInfo })),
          catchError(() => {
            return of(loadWebsiteSyncInfoFail());
          }),
        ),
      ),
    );
  });

  loadWebsiteCountry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadWebsiteCountry),
      concatLatestFrom(() => this.websiteId$),
      filter(([, id]) => !!id),
      switchMap(([, id]) =>
        this.service.getCountry(id).pipe(
          map((country) => loadWebsiteCountrySuccess({ country })),
          catchError(() => {
            return of(loadWebsiteCountryFail());
          }),
        ),
      ),
    );
  });

  connectChannel$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(connectChannel),
        withLatestFrom(this.store.select(selectUserId), this.store.select(selectWebsiteGeneral)),
        tap(([{ channelType }, userId, site]) => {
          this.document.location.href = `${environment.apiUrl}/import/auth?user=${userId}&site=${site?.id}&type=${channelType}`;
        }),
      );
    },
    { dispatch: false },
  );

  syncChannel$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(syncChannel),
        withLatestFrom(this.store.select(selectUserId), this.store.select(selectWebsiteGeneral)),
        tap(([{ channelType }, userId, site]) => {
          this.document.location.href = `${environment.apiUrl}/import/${site?.id}/synchronize/${channelType}?user=${userId}`;
        }),
      );
    },
    { dispatch: false },
  );

  skipObjective$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(skipObjective),
        concatLatestFrom(() => this.store.select(selectWebsiteGeneral)),
        tap(([{ tag }, website]) => {
          this.service.skipObjective(website?.id as number, tag).subscribe((message) => {
            this.notification.show(message, 'primary');
          });
        }),
      );
    },
    { dispatch: false },
  );

  resumeObjective$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(resumeObjective),
        concatLatestFrom(() => this.store.select(selectWebsiteGeneral)),
        tap(([{ objectiveTag }, website]) => {
          this.service.resumeObjective(website?.id as number, objectiveTag).subscribe(() => {
            this.router.navigateByUrl(`/objective/${objectiveTag}`);
          });
        }),
      );
    },
    { dispatch: false },
  );

  channelConnectSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(connectChannelSuccess),
        tap(({ channelType }) => {
          if (channelType === 'ganalytics') {
            this.dashboardPollingStartTime = Date.now();
          }
        }),
      );
    },
    { dispatch: false },
  );

  dashboardPollingFinished$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(dashboardPollingFinished),
        tap(() => {
          if (this.dashboardPollingStartTime) {
            this.dashboardPollingStartTime = 0;
          }
        }),
      );
    },
    { dispatch: false },
  );

  dashboardPollingStartTime: number;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private actions$: Actions,
    private service: WebsiteService,
    private store: Store<AppState>,
    private router: Router,
    private notification: NotificationService,
    private storage: StorageService,
  ) {}
}
