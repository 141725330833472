import { FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { OverlayModule } from '@angular/cdk/overlay';
import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ClipboardModule } from 'ngx-clipboard';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { AuthService, createErrorHandler } from './core/services';
import { FBPixelModule } from './core/services/fb-pixel/fb-pixel.module';
import { NgZorroConfigModule } from './ng-zorro-config.module';
import { DashboardService } from './services/dashboard.service';
import { environment } from '../environments/environment';
import { WindowProvider } from './shared/helpers/tokens';
import { BillingEffects } from './state/billing/effects';
import { CountriesEffects } from './state/countries/effects';
import { CustomSerializer } from './state/custom-serializer';
import { AppEffects } from './state/effects.service';
import { setAcp } from './state/global.actions';
import { IndustriesEffects } from './state/industries/effects';
import { MetricsEffects } from './state/metrics/effects';
import { AppState, metaReducers, reducers } from './state/reducers';
import { RouterEffects } from './state/router.effects';
import { UserEffects } from './state/user/effects';
import { WebsiteEffects } from './state/website/effects';
import { WebsiteListEffects } from './state/website-list/effects';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([
      AppEffects,
      RouterEffects,
      UserEffects,
      IndustriesEffects,
      WebsiteListEffects,
      WebsiteEffects,
      CountriesEffects,
      BillingEffects,
      MetricsEffects,
    ]),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      serializer: CustomSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    OverlayModule,
    NgZorroConfigModule,
    SocialLoginModule,
    ClipboardModule,
    FBPixelModule.forRoot({
      enabled: environment.production || environment.name === 'staging',
      pixelId: environment.facebookPixelKey,
    }),
    CoreModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/app' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    DashboardService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookKey),
          },
        ],
        onError: (err) => {
          console.error(`[SocialAuthServiceConfig]: ${err}`);
        },
      } as SocialAuthServiceConfig,
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        logErrors: !environment.production,
      }),
    },
    { ...WindowProvider },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(
    private auth: AuthService,
    private store: Store<AppState>,
  ) {
    this.auth.login$.subscribe(({ acp }) => {
      this.store.dispatch(setAcp({ acp }));
    });
  }
}
