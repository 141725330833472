// eslint-disable-next-line max-classes-per-file
import { Component, ComponentFactoryResolver, Injector, NgModule, TemplateRef, ViewChild } from '@angular/core';
import { NZ_CONFIG, NzConfig } from 'ng-zorro-antd/core/config';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';

import { MaterialModule } from './shared/material.module';

// The module-level Component which contains template references.
// Exporting is required for AOT compatibility
@Component({
  template: `
    <ng-template #nzSuffixIconTmpl>
      <mat-icon svgIcon="select-arrow"></mat-icon>
    </ng-template>
  `,
  standalone: false,
})
export class GlobalTemplatesComponent {
  @ViewChild('nzSuffixIconTmpl', { static: true })
  nzSuffixIcon!: TemplateRef<void>;
}

export const nzConfigFactory = (injector: Injector, resolver: ComponentFactoryResolver): NzConfig => {
  const factory = resolver.resolveComponentFactory(GlobalTemplatesComponent);
  const { nzSuffixIcon } = factory.create(injector).instance;

  return {
    select: {
      nzSuffixIcon,
    },
  };
};

@NgModule({
  imports: [MaterialModule],
  declarations: [GlobalTemplatesComponent],
  providers: [
    {
      provide: NZ_CONFIG,
      useFactory: nzConfigFactory,
      deps: [Injector, ComponentFactoryResolver],
    },
    { provide: NZ_I18N, useValue: en_US },
  ],
})
export class NgZorroConfigModule {}
