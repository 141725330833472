import { FacebookLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-facebook-auth-button',
  templateUrl: './facebook-auth-button.component.html',
  styleUrls: ['./facebook-auth-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FacebookAuthButtonComponent {
  @Output() userReceived = new EventEmitter<SocialUser>();

  constructor(private socialAuthService: SocialAuthService) {}

  loginWithFacebook(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
      this.userReceived.emit(user);
    });
  }
}
