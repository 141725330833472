import { ActiveUserSubscription, UserSubscription } from '@common/models';

import { EntityState } from '../../common';
import { PaymentMethodDTO } from '../../dashboard/billing/models';

export interface BillingState {
  billing: PaymentMethodDTO | null;
  billingState: EntityState;
  subscription: UserSubscription | null;
  subscriptions: ActiveUserSubscription[] | null;
  isProAccessProvided: boolean;
  proAccessState: EntityState;
  isDataReceivedFromServer: boolean; // is local flag for understanding, is it data already received from server or not yet
}

const defaultBillingState: BillingState = {
  billing: null,
  billingState: EntityState.NotLoaded,
  subscription: null,
  subscriptions: null,
  isProAccessProvided: false,
  proAccessState: EntityState.NotLoaded,
  isDataReceivedFromServer: false, // is local flag for understanding, is it data already received from server or not yet
};

export const DEFAULT_BILLING_STATE = defaultBillingState;
