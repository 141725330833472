import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { NotificationService } from '../../_notification/notification.service';
import { AuthService } from '../../core/services';

interface ForgotPasswordForm {
  email: FormControl<null>;
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  standalone: false,
})
export class ForgotPasswordComponent {
  formGroup: FormGroup = this.fb.group<ForgotPasswordForm>({
    email: this.fb.control<null>(null, [Validators.required]),
  });
  submitted: boolean = false;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private notification: NotificationService,
  ) {}

  submit(): void {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      const email = this.formGroup.value.email.toLowerCase();

      this.auth.forgotPassword({ email }).subscribe({
        next: () => {
          this.submitted = true;
        },
        error: (response: HttpErrorResponse) => {
          this.notification.show(response.error.message as string, 'danger');
        },
      });
    }
  }
}
