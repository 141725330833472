<app-auth-layout>
  <a routerLink="/login"><img class="back" src="assets/images/icons/uil_arrow-left.svg" alt="" /></a>

  <div class="form" [formGroup]="formGroup">
    <h1 class="title">Forgot password?</h1>

    <ng-container *ngIf="!submitted">
      <div class="subtitle">Don't have an account? <a routerLink="/signup">Sign Up</a></div>
      <app-notification-outlet></app-notification-outlet>
      <input type="email" name="email" placeholder="Email" formControlName="email" autofocus />
      <button app-button class="primary" (click)="submit()">Send reset link</button>
    </ng-container>

    <ng-container *ngIf="submitted">
      <div class="sent">
        An email with instructions was sent to <strong>{{ formGroup.value.email }}</strong>
      </div>
      <button app-button view="link" class="outlined" routerLink="/login">Return to sign in</button>
    </ng-container>
  </div>

  <div class="illustration"><img src="assets/images/illustration.svg" alt="illustration" /></div>
</app-auth-layout>
