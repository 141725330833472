import { createReducer, on } from '@ngrx/store';

import { loadMetrics, loadMetricsFail, loadMetricsSuccess, resetMetricsState, updateState } from './actions';
import { DEFAULT_METRICS_STATE } from './state';
import { EntityState } from '../../common';

export const metricsReducer = createReducer(
  DEFAULT_METRICS_STATE,
  on(loadMetrics, (state, { metricsName }) => ({
    ...state,
    metricsName: [...state.metricsName, ...metricsName],
    entityState: EntityState.Loading,
  })),
  on(loadMetricsSuccess, (state, { metricsState }) => ({
    ...state,
    metricsName: [],
    metricsState: { ...state.metricsState, ...metricsState },
    entityState: EntityState.Loaded,
  })),
  on(loadMetricsFail, (state) => ({ ...state, entityState: EntityState.Error })),
  on(updateState, (state, { metricsName, metricsState }) => ({
    ...state,
    metricsName,
    metricsState: { ...state.metricsState, ...metricsState },
  })),
  on(resetMetricsState, () => ({
    ...JSON.parse(JSON.stringify(DEFAULT_METRICS_STATE)),
    entityState: EntityState.Loaded,
  })),
);
