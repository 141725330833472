<app-auth-layout *ngIf="!initializing; else loadingTmpl">
  <div class="form" [formGroup]="formGroup">
    <h1 class="title">Sign in</h1>
    <app-notification-outlet></app-notification-outlet>

    <input id="username-input" type="email" name="email" placeholder="Email" formControlName="username" autofocus />
    <input id="password-input" type="password" name="password" placeholder="Password" formControlName="password" />
    <button class="button" id="sign-in-btn" app-button (click)="submit()">Sign In</button>
    <a class="ref" routerLink="/forgot-password">Forgot Password?</a>

    <app-facebook-auth-button (userReceived)="onUserReceivedFromFacebook($event)"></app-facebook-auth-button>
    <ng-template #facebookMessageContainer></ng-template>

    <app-google-auth-button
      *ngIf="isGoogleApiLoaded$ | async"
      class="button--last"
      (tokenReceived)="onTokenReceivedFromGoogle($event)"
    ></app-google-auth-button>
    <ng-template #googleMessageContainer></ng-template>

    <div class="recaptacha-terms">
      This site is protected by reCAPTCHA and the Google
      <a class="ref" href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a class="ref" href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>

    <ng-container *ngIf="loading" [ngTemplateOutlet]="loadingTmpl"></ng-container>
  </div>
</app-auth-layout>

<ng-template #loadingTmpl>
  <div class="loading-wrapper">
    <app-loading-placeholder></app-loading-placeholder>
  </div>
</ng-template>
