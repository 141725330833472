import { HttpErrorResponse } from '@angular/common/http';

export class InvalidCaptchaError extends Error {
  protected response: HttpErrorResponse;

  constructor(response: HttpErrorResponse) {
    super('Invalid captcha');
    this.response = response;

    Object.setPrototypeOf(this, InvalidCaptchaError.prototype);
  }
}
