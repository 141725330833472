import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs';

import { loadIndustries, loadIndustriesSuccess, setActiveIndustry } from './actions';
import { IndustryApiService } from '../../core/services';
import { loadMetrics } from '../metrics/actions';

@Injectable()
export class IndustriesEffects {
  loadIndustries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadIndustries),
      exhaustMap(() =>
        this.industriesService.getIndustries().pipe(map((industries) => loadIndustriesSuccess({ industries }))),
      ),
    ),
  );

  setActiveIndustry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setActiveIndustry),
      exhaustMap(({ websiteId, industryId }) =>
        this.industriesService
          .setIndustry(websiteId, industryId)
          .pipe(map(() => loadMetrics({ metricsName: ['industry'] }))),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private industriesService: IndustryApiService,
  ) {}
}
