import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { loadWebsites, loadWebsitesFail, loadWebsitesSuccess } from './actions';
import { StorageService } from '../../core/services';
import { DashboardService } from '../../services/dashboard.service';
import { loadWebsite, setCurrentWebsiteId } from '../website/actions';

@Injectable()
export class WebsiteListEffects {
  loadWebsites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadWebsites),
      switchMap(() =>
        this.dashboardService.getWebsites().pipe(
          map((websites) => loadWebsitesSuccess({ websites })),
          catchError(() => of(loadWebsitesFail())),
        ),
      ),
    ),
  );

  loadWebsitesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadWebsitesSuccess),
      filter(({ websites }) => websites && websites.length > 0),
      switchMap(({ websites }) => {
        const recentWebsiteId = localStorage.getItem('recentWebsiteId');

        if (recentWebsiteId) {
          const websiteId = parseInt(recentWebsiteId);
          const websiteDetected = !isNaN(websiteId) && websites.find((w) => w.id === websiteId);

          if (websiteDetected) {
            return of(setCurrentWebsiteId({ currentWebsiteId: websiteId }));
          } else {
            return of(setCurrentWebsiteId({ currentWebsiteId: websites[0].id }));
          }
        } else {
          return of(setCurrentWebsiteId({ currentWebsiteId: websites[0].id }));
        }
      }),
    ),
  );

  loadCurrentWebsite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCurrentWebsiteId),
      switchMap(({ currentWebsiteId }) => {
        if (currentWebsiteId) {
          this.storage.writeData('recentWebsiteId', currentWebsiteId);

          return of(loadWebsite({ id: currentWebsiteId }));
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
    private storage: StorageService,
  ) {}
}
