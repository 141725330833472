import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import {
  loadContactInfo,
  loadContactInfoSuccess,
  loadFeaturedMemberStatus,
  loadUser,
  loadUserActivity,
  loadUserActivitySuccess,
  loadUserSuccess,
  setFeaturedMemberStatus,
} from './actions';
import { AuthService, FeaturedMemberService, HubspotService } from '../../core/services';
import { UserService } from '../../services/user.service';
import { loadActiveSubscriptions, loadHasProAccess, loadPaymentMethod, loadSubscription } from '../billing/actions';
import { loadAllCountries } from '../countries/actions';
import { loadWebsites } from '../website-list/actions';

@Injectable()
export class UserEffects {
  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUser),
      exhaustMap(() =>
        this.auth.getUserInfo().pipe(
          tap((user) => {
            this.hubspotService.identifyVisitor(user.id, user.email);
          }),
          map((user) => loadUserSuccess({ user })),
        ),
      ),
    ),
  );

  loadUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserSuccess),
      switchMap(() => [
        loadWebsites(),
        loadAllCountries(),
        loadHasProAccess(),
        loadFeaturedMemberStatus(),
        loadSubscription(),
        loadPaymentMethod(),
        loadActiveSubscriptions(),
        loadContactInfo(),
      ]),
    ),
  );

  loadFeaturedMemberStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFeaturedMemberStatus),
      exhaustMap(() =>
        this.featuredMemberService
          .getStatus()
          .pipe(map((featuredMemberStatus) => setFeaturedMemberStatus({ status: featuredMemberStatus }))),
      ),
    ),
  );

  loadContactInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContactInfo),
      exhaustMap(() => this.userService.getContactInfo().pipe(map((contacts) => loadContactInfoSuccess({ contacts })))),
    ),
  );

  loadUserActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserActivity),
      exhaustMap(() =>
        this.userService.getActivityAction().pipe(
          map((activity) => {
            if (typeof activity === 'string') {
              return loadUserActivitySuccess({ activity: JSON.parse(activity) });
            }

            if (Array.isArray(activity) && !activity.length) {
              return loadUserActivitySuccess({ activity: [] });
            }

            return loadUserActivitySuccess({ activity: null });
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private auth: AuthService,
    private featuredMemberService: FeaturedMemberService,
    private userService: UserService,
    private hubspotService: HubspotService,
  ) {}
}
