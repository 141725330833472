<app-auth-layout>
  <a routerLink="/login"><img class="back" src="assets/images/icons/uil_arrow-left.svg" alt="Go back" /></a>

  <div class="form" [formGroup]="form">
    <h1 class="title">Reset password?</h1>

    <ng-container *ngIf="!submitted">
      <div class="subtitle">Don't have an account? <a routerLink="/signup">Sign Up</a></div>
      <app-notification-outlet></app-notification-outlet>

      <div class="form-item">
        <input id="password" type="password" formControlName="password" placeholder="New password" />

        <div *ngIf="password.invalid && (password.dirty || password.touched)" class="error-message">
          <div *ngIf="password.errors?.required">Password is required</div>
          <div *ngIf="password.errors?.minlength">Password should be at least 8 characters long</div>
        </div>
      </div>

      <div class="form-item">
        <input id="password-repeat" type="password" formControlName="repeated" placeholder="Repeat new password" />

        <div *ngIf="repeated.invalid && (repeated.dirty || repeated.touched)" class="error-message">
          <div *ngIf="repeated.errors?.match">Passwords do not match</div>
        </div>
      </div>

      <button app-button class="primary" id="submit-password-reset" (click)="submit()">Set new password</button>
    </ng-container>

    <ng-container *ngIf="submitted">
      <button app-button view="link" class="outlined" routerLink="/login">Return to sign in</button>
    </ng-container>
  </div>

  <div class="illustration"><img src="assets/images/illustration.svg" alt="illustration" /></div>
</app-auth-layout>
