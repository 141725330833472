import { Component } from '@angular/core';

import { DIIB_DOMAIN_AUTHORITY, GLOBAL_USERS } from '../../../common';
import { FREE_PRICE } from '../../../common/pricing-entity';
import { PRO_PRICE } from './../../../common/pricing-entity/pricing-id-dictionary';

@Component({
  selector: 'app-signup-faq',
  templateUrl: './signup-faq.component.html',
  styleUrls: ['./signup-faq.component.scss'],
  standalone: false,
})
export class SignUpFAQComponent {
  readonly globalUsers = GLOBAL_USERS;
  readonly freePrice = FREE_PRICE;
  readonly proPrice = PRO_PRICE;
  readonly DA = DIIB_DOMAIN_AUTHORITY;
  readonly actualDate = new Date();
}
