import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logos',
  imports: [CommonModule],
  templateUrl: './logos.component.html',
  styleUrls: ['./logos.component.scss'],
})
export class LogosComponent implements OnInit {
  readonly initial: string[] = ['entrepreneur.png', 'google.svg', 'fb.png'];
  steps: { [key: number]: string }[] = [
    { 0: 'usa-today.svg' },
    { 1: 'cbs.svg' },
    { 2: 'the-telegraph.svg' },
    { 0: 'nbc.svg' },
    { 1: 'google.svg' },
    { 2: 'fb.png' },
    { 0: 'entrepreneur.png' },
  ];

  currentLogos: string[] = this.initial;
  currentStepIndex: number = 0;

  ngOnInit(): void {
    setInterval(() => {
      Object.entries(this.steps[this.currentStepIndex]).forEach(([index, logo]) => (this.currentLogos[index] = logo));
      this.currentStepIndex = (this.currentStepIndex + 1) % this.steps.length;
    }, 2000);
  }
}
